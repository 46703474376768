import { useState } from "react";
import styles from "./games-menu-new.module.css";
import { NavLink } from "react-router-dom";

function GamesMenuNew({ gamesData, activeCategory, setActiveCategory }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    Звукопроизношение: true,
    "Развитие речи": false,
    "Слуховое восприятие": false,
    "Память, внимание и логика": false,
    Математика: false,
    "Окружающий мир": false,
  });
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isSubcategoryActive = (
    categoryName,
    subcategoryName,
    subcategoryCount
  ) => {
    const isChosen =
      activeCategory.category === categoryName &&
      activeCategory.subcategory === subcategoryName;

    if (isChosen) {
      return styles.activeSubcategory;
    }

    if (subcategoryCount > 0) {
      return styles.subcategoryGames;
    }

    return styles.nonActiveLink;
  };

  const isCategoryActive = (categoryName) => {
    return activeCategory.category === categoryName ? styles.activeLink : "";
  };

  const handleCategoryOrSubcategoryClick = (
    event,
    category,
    subcategory = null
  ) => {
    event.stopPropagation();
    setActiveCategory({ category, subcategory });

    // Если подкатегория не задана, переключаем состояние выпадающего списка
    if (subcategory === null) {
      setDropdownOpen((prevState) => ({
        ...prevState,
        [category]: !prevState[category],
      }));
    }
  };

  const handleSubcategoryClick = (event, category, subcategory) => {
    event.stopPropagation(); // Это предотвратит всплытие события
    setActiveCategory({ category, subcategory });
  };
  const countGames = (gamesData) => {
    return gamesData.reduce((acc, { category, subcategory }) => {
      acc[category] = acc[category] || { total: 0, subcategories: {} };
      acc[category].total++;
      acc[category].subcategories[subcategory] =
        (acc[category].subcategories[subcategory] || 0) + 1;
      return acc;
    }, {});
  };

  const categoryOrder = [
    "Звукопроизношение",
    "Развитие речи",
    "Слуховое восприятие",
    "Память, внимание и логика",
    "Математика",
    "Окружающий мир",
  ];

  const gamesCount = countGames(gamesData);

  const renderSubcategories = (categoryName, subcategories) => {
    return (
      <ul className={styles.dropdown}>
        {Object.keys(subcategories).map((subcategory) => (
          <li
            key={subcategory}
            onClick={(event) =>
              handleSubcategoryClick(event, categoryName, subcategory)
            }
            className={isSubcategoryActive(
              categoryName,
              subcategory,
              subcategories[subcategory]
            )}
          >
            {subcategory}{" "}
            <span className={styles.quantityGamesText}>
              ({subcategories[subcategory]})
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const renderCategories = () => {
    return categoryOrder.map((categoryName) => (
      <li
        key={categoryName}
        className={
          dropdownOpen[categoryName]
            ? `${styles.liBase} ${styles.liOpen}`
            : styles.liBase
        }
        onClick={(event) =>
          handleCategoryOrSubcategoryClick(event, categoryName)
        }
      >
        <NavLink className={isCategoryActive(categoryName)}>
          <div className={styles.categoryContainer}>
            <span className={styles.categoryName}>{categoryName}</span>
            <span className={styles.quantityGamesText}>
              ({gamesCount[categoryName]?.total || 0})
            </span>
          </div>
        </NavLink>
        {dropdownOpen[categoryName] &&
          renderSubcategories(
            categoryName,
            gamesCount[categoryName]?.subcategories || {}
          )}
      </li>
    ));
  };

  return (
    <div className={styles.menuContainer}>
      <nav className={`${styles.header}`}>
        <ul className={styles.links}>{renderCategories()}</ul>
        <button className={styles.hamburger} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>
    </div>
  );
}

export default GamesMenuNew;
