import { Link } from "react-router-dom";
import styles from "./games-list-new.module.css";
import { useState } from "react";

import blueSpot from "./images/blueSpot.png";
import greenSpot from "./images/greenSpot.png";
import orangeSpot from "./images/orangeSpot.png";
import cubes from "./images/cubes.png";

export function GamesListNew({ gamesData, activeCategory }) {
  const handleClick = () => {
    // console.log("click");
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredGames = gamesData.filter((game) => {
    return (
      game.category === activeCategory.category &&
      (!activeCategory.subcategory ||
        game.subcategory === activeCategory.subcategory)
    );
  });

  const uniqueGames = filteredGames.reduce((acc, currentGame) => {
    const isDuplicate = acc.some((game) => game.title === currentGame.title);

    if (!isDuplicate) {
      acc.push(currentGame);
    }
    return acc;
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("Поиск по:", searchTerm);
    }
  };

  return (
    <div className={styles.centerContainer}>
      <div className={styles.searchBar}>
        <div className={styles.searchIcon}></div>
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Поиск игр..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
        />
      </div>

      <div className={styles.containerWrapper}>
        {uniqueGames.length >= 4 && (
          <>
            <img src={blueSpot} className={styles.blueSpot} alt="пятно" />
            <img src={greenSpot} className={styles.greenSpot} alt="пятно" />
            <img src={orangeSpot} className={styles.orangeSpot} alt="пятно" />

            <img src={cubes} className={styles.cubes} alt="кубики" />
          </>
        )}
        <div className={styles.container}>
          {uniqueGames.map((game) => (
            <div key={game.id} className={styles.gameContainer}>
              <div className={styles.imageContainer}>
                <img
                  src={game.imageUrl}
                  alt={game.title}
                  className={styles.image}
                />
                <h4 className={styles.gameHeader}>{game.title}</h4>
                <p className={styles.gameDescription}>{game.description}</p>
                <Link to={game.path}>
                  <button className={styles.btn} onClick={handleClick}>
                    Играть
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GamesListNew;
