import { useParams } from "react-router-dom";
import NotFound from "../not-found/not-found";
import GameLoader from "../loader/game-loader";
import { lazy, Suspense } from "react";
import ClipLoader from "react-spinners/ClipLoader";
// import RabbitGame from "../games-folder/rabbit-game/rabbit-game";
// import AssociationsGame from "../games-folder/associations/associations";
// import FrogGame from "../games-folder/frogs-game/frogs-game";
// import FishGame from "../games-folder/fish-game/fish-game";
// import BiggerSmallerGame from "../games-folder/bigger-smaller/bigger-smaller-game";
// import SeasonsGame from "../games-folder/seasons-game/seasons-game";
// import ArtistMistakesGame from "../games-folder/artist-mistakes/artist-mistakes-game";
// import FindInRoomGame from "../games-folder/find-in-room-game/find-in-room";
// import AnimalsInForestGame from "../games-folder/animals-in-forest/animals-in-forest";
// import AnimalsHotCountriesGame from "../games-folder/animals-hot-countries/animals-hot-countries";
// import GuessActionGame from "../games-folder/guess-action/guess-action";
// import AnimalsAtFarm from "../games-folder/animals-farm/animals-farm";
// import FindInParkGame from "../games-folder/find-in-park/find-in-park";
// import DinosaurGame from "../games-folder/dinosaur/dinosaur";
// import HomeAppliancesGame from "../games-folder/home-appliances/home-appliances";
// import AnimalsSoundsGame from "../games-folder/animals-sounds/animals-sounds";
// import CountrysideGame from "../games-folder/countryside/countryside";
// import StreerSoundsGame from "../games-folder/street-sounds/street-sounds";
// import TransportSoundsGame from "../games-folder/transport-sounds/transport-sounds";
// import NatureSoundsGame from "../games-folder/nature-sounds/nature-sounds";
// import EmotionsSoundsGame from "../games-folder/emotions-sounds/emotions-sounds";
// import BirdsSoundsGame from "../games-folder/birds-sounds/birds-sounds";
// import MusicalInstrumentsSoundsGames from "../games-folder/musical-instruments-sounds/musical-instruments-sounds";
// import InsectsSoundsGame from "../games-folder/insects-sounds/insects-sounds";
// import AnimalsSoundsTutorial from "../games-folder/tutorials/animals-sounds-tutorial/animals-sounds-tutorial";
// import HomeAppliancesTutorial from "../games-folder/tutorials/home-appliances-tutorial/home-appliances-tutorial";
// import CountrysideTutorial from "../games-folder/tutorials/countryside-tutorial/countryside-tutorial";
// import StreetSoundsTutorial from "../games-folder/tutorials/street-sounds-tutorial/street-sounds-tutorial";
// import TransportSoundsTutorial from "../games-folder/tutorials/transport-sounds-tutorial/transport-sounds-tutorial";
// import NatureSoundsTutorial from "../games-folder/tutorials/nature-sounds-tutorial/nature-sounds-tutorial";
// import EmotionsSoundsTutorial from "../games-folder/tutorials/emotions-sounds-tutorial/emotions-sounds-tutorial";
// import BirdsSoundsTutorial from "../games-folder/tutorials/birds-sounds-tutorial/birds-sounds-tutorial";
// import MusicalInstrumentsSoundsTutorial from "../games-folder/tutorials/musical-instruments-sounds-tutorial/musical-instruments-sounds-tutorial";
// import InsectsSoundsTutorial from "../games-folder/tutorials/insects-sounds-tutorial/insects-sounds-tutorial";
// import MathFigureOneGame from "../games-folder/math-one/math-one";
// import MathFigureTwoGame from "../games-folder/math-two/math-two";
// import MathFigureThreeGame from "../games-folder/math-three/math-three";
// import MathFigureFourGame from "../games-folder/math-four/math-four";
// import MathFigureFiveGame from "../games-folder/math-five/math-five";
// import MathFigureSixGame from "../games-folder/math-six/math-six";
// import MathFigureSevenGame from "../games-folder/math-seven/math-seven";
// import MathFigureEightGame from "../games-folder/math-eight/math-eight";
// import MathFigureNineGame from "../games-folder/math-nine/math-nine";
// import MathFigureTenGame from "../games-folder/math-ten/math-ten";
// import AngryGoatGame from "../games-folder/angry-goat/angry-goat";
// import MaigcLampsGame from "../games-folder/magic-lamps/magic-lamps";
// import BumbleBeeGame from "../games-folder/bumblebee/bumblebee";
// import WhatIsInSnakesHoleGame from "../games-folder/snakes-hole/snakes-hole";
// import DatingWithZhuzhaMain from "../games-folder/dating-with-zhuzha/dating-with-zhuzha-main";
// import AcornWithSurpriseGame from "../games-folder/acorn-with-surprise/acorn-with-surprise";
// import PickingFruitsGame from "../games-folder/picking-fruits/picking-fruits";
// import WhatOwlFoundInForestGame from "../games-folder/what-owl-found-in-forest/owl-found-in-forest";
// import StudyingCircleGame from "../games-folder/studying-circle/studying-circle";
// import StudyingSquareGame from "../games-folder/studying-square/studying-square";
// import StudyingTriangleGame from "../games-folder/studying-triangle/studying-triangle";
// import StudyingRectangleGame from "../games-folder/studying-rectangle/studying-rectangle";
// import StudyingRhombGame from "../games-folder/studying-rhomb/studying-rhomb";
// import StudyingOvalGame from "../games-folder/studying-oval/studying-oval";
// import BallDancingGame from "../games-folder/ball-dancing/ball-dancing";
// import CreateHumanGame from "../games-folder/create-a-human/create-a-human";
// import CreateFaceGame from "../games-folder/create-face/create-face";
// import WatchCinemaGame from "../games-folder/watch-cinema/watch-cinema";
// import MostAttentiveGame from "../games-folder/most-attentive/most-attentive";
// import LookBinocularsGame from "../games-folder/look-binoculars/look-binoculars";
// import TalkingProjectorGame from "../games-folder/talking-projector/talking-projector";
// import LossingBoyGame from "../games-folder/lossing-boy/lossing-boy";
// import GrandmaHarvestGame from "../games-folder/grandma-harvest/grandma-harves-main";
// import DiamondsWithSurpriseGame from "../games-folder/diamonds-with-surprise/diamonds-with-surprise";
// import ShoppingAheadGame from "../games-folder/shopping-ahead/shopping-ahead-main";
// import LostThingsGame from "../games-folder/lost-things/lost-things";
// import LockersGame from "../games-folder/lockers/lockers";
// import FlyTravellerGame from "../games-folder/fly-traveller/fly-traveller-main";
// import HungryFlyGame from "../games-folder/hungry-fly/hungry-fly";
// import FindTurtlesGame from "../games-folder/find-turtles/find-turtles";
// import TeaPartyGame from "../games-folder/tea-party/tea-party";
// import DobbleGame from "../games-folder/dobble/dobble";
// import RabbitInGuestsGame from "../games-folder/rabbit-in-guests/rabbit-in-guests";
// import ChristmasSocksGame from "../games-folder/christmas-socks/christmas-socks";
// import LizardsAndTailsGame from "../games-folder/lizards-and-tails/lizards-and-tails";
// import FishMarketGame from "../games-folder/fish-market/fish-market";
// import WitchSpellsGame from "../games-folder/witch-spells/witch-spells";
// import PlayPianoGame from "../games-folder/play-piano/play-piano";
// import HeronInSwampGame from "../games-folder/heron-in-swamp/heron-in-swamp";
// import BigManufactureGame from "../games-folder/big-manufacture/big-manufacture";
// import ShoppingListGame from "../games-folder/shopping-list/shopping-list";
// import AcquaintanceMushroomsGame from "../games-folder/acquaintance-mushrooms/acquaintance-mushrooms-main";
// import ForestLifeGame from "../games-folder/forest-life/forest-life-main";
// import FurnitureHouseGame from "../games-folder/furniture-house/furniture-house-main";
// import LionHuntGame from "../games-folder/lion-hunt/lion-hunt";
// import MeltingIceGame from "../games-folder/melting-ice/melting-ice";
// import SleplessNightGame from "../games-folder/slepless-night/slepless-night";
// import LetsPlayWithToysGame from "../games-folder/lets-play-with-toys/lets-play-with-toys";
// import ChickenKoKoGame from "../games-folder/chicken-ko-ko/chicken-ko-ko";
// import TrickyScalesGame from "../games-folder/tricky-scales/tricky-scales";
// import HelpCookerGame from "../games-folder/help-cooker/help-cooker";
// import SortPurchasesIntoBagGame from "../games-folder/sort-purchases-into-bags/sort-purchases-into-bags";
// import DobbleLGame from "../games-folder/dobble-l/dobble-l";
// import HeavierLighterGame from "../games-folder/heavier-lighter/heavier-lighter";
// import DobbleCGame from "../games-folder/dobble-c/dobble-c";

const gameComponentsMap = {
  rabbit: lazy(() => import("../games-folder/rabbit-game/rabbit-game")),
  associations: lazy(() => import("../games-folder/associations/associations")),
  frogs: lazy(() => import("../games-folder/frogs-game/frogs-game")),
  fish: lazy(() => import("../games-folder/fish-game/fish-game")),
  biggerSmaller: lazy(() =>
    import("../games-folder/bigger-smaller/bigger-smaller-game")
  ),
  seasons: lazy(() => import("../games-folder/seasons-game/seasons-game")),
  artistMistakes: lazy(() =>
    import("../games-folder/artist-mistakes/artist-mistakes-game")
  ),
  findInRoom: lazy(() =>
    import("../games-folder/find-in-room-game/find-in-room")
  ),
  animalsInForest: lazy(() =>
    import("../games-folder/animals-in-forest/animals-in-forest")
  ),
  animalsHotCountries: lazy(() =>
    import("../games-folder/animals-hot-countries/animals-hot-countries")
  ),
  guessAction: lazy(() => import("../games-folder/guess-action/guess-action")),
  animalsAtFarm: lazy(() =>
    import("../games-folder/animals-farm/animals-farm")
  ),
  findInPark: lazy(() => import("../games-folder/find-in-park/find-in-park")),
  dinosaur: lazy(() => import("../games-folder/dinosaur/dinosaur")),
  homeAppliances: lazy(() =>
    import("../games-folder/home-appliances/home-appliances")
  ),
  animalsSounds: lazy(() =>
    import("../games-folder/animals-sounds/animals-sounds")
  ),
  countryside: lazy(() => import("../games-folder/countryside/countryside")),
  streetSounds: lazy(() =>
    import("../games-folder/street-sounds/street-sounds")
  ),
  transportSounds: lazy(() =>
    import("../games-folder/transport-sounds/transport-sounds")
  ),
  natureSounds: lazy(() =>
    import("../games-folder/nature-sounds/nature-sounds")
  ),
  emotionsSounds: lazy(() =>
    import("../games-folder/emotions-sounds/emotions-sounds")
  ),
  birdsSounds: lazy(() => import("../games-folder/birds-sounds/birds-sounds")),
  musicalInstrumentsSounds: lazy(() =>
    import(
      "../games-folder/musical-instruments-sounds/musical-instruments-sounds"
    )
  ),
  insectsSounds: lazy(() =>
    import("../games-folder/insects-sounds/insects-sounds")
  ),
  animalsSoundsTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/animals-sounds-tutorial/animals-sounds-tutorial"
    )
  ),
  homeAppliancesTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/home-appliances-tutorial/home-appliances-tutorial"
    )
  ),
  countrysideTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/countryside-tutorial/countryside-tutorial"
    )
  ),
  streetSoundsTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/street-sounds-tutorial/street-sounds-tutorial"
    )
  ),
  transportSoundsTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/transport-sounds-tutorial/transport-sounds-tutorial"
    )
  ),
  natureSoundsTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/nature-sounds-tutorial/nature-sounds-tutorial"
    )
  ),
  emotionsSoundsTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/emotions-sounds-tutorial/emotions-sounds-tutorial"
    )
  ),
  birdsSoundsTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/birds-sounds-tutorial/birds-sounds-tutorial"
    )
  ),
  musicalInstrumentsSoundsTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/musical-instruments-sounds-tutorial/musical-instruments-sounds-tutorial"
    )
  ),
  insectsSoundsTutorial: lazy(() =>
    import(
      "../games-folder/tutorials/insects-sounds-tutorial/insects-sounds-tutorial"
    )
  ),
  mathFigureOne: lazy(() => import("../games-folder/math-one/math-one")),
  mathFigureTwo: lazy(() => import("../games-folder/math-two/math-two")),
  mathFigureThree: lazy(() => import("../games-folder/math-three/math-three")),
  mathFigureFour: lazy(() => import("../games-folder/math-four/math-four")),
  mathFigureFive: lazy(() => import("../games-folder/math-five/math-five")),
  mathFigureSix: lazy(() => import("../games-folder/math-six/math-six")),
  mathFigureSeven: lazy(() => import("../games-folder/math-seven/math-seven")),
  mathFigureEight: lazy(() => import("../games-folder/math-eight/math-eight")),
  mathFigureNine: lazy(() => import("../games-folder/math-nine/math-nine")),
  mathFigureTen: lazy(() => import("../games-folder/math-ten/math-ten")),
  angryGoat: lazy(() => import("../games-folder/angry-goat/angry-goat")),
  magicLamps: lazy(() => import("../games-folder/magic-lamps/magic-lamps")),
  bumbleBee: lazy(() => import("../games-folder/bumblebee/bumblebee")),
  whatIsInSnakesHole: lazy(() =>
    import("../games-folder/snakes-hole/snakes-hole")
  ),
  datingWithZhuzha: lazy(() =>
    import("../games-folder/dating-with-zhuzha/dating-with-zhuzha-main")
  ),
  acornWithSurprise: lazy(() =>
    import("../games-folder/acorn-with-surprise/acorn-with-surprise")
  ),
  pickingFruits: lazy(() =>
    import("../games-folder/picking-fruits/picking-fruits")
  ),
  whatOwlFoundInForest: lazy(() =>
    import("../games-folder/what-owl-found-in-forest/owl-found-in-forest")
  ),
  studyingCircle: lazy(() =>
    import("../games-folder/studying-circle/studying-circle")
  ),
  studyingSquare: lazy(() =>
    import("../games-folder/studying-square/studying-square")
  ),
  studyingTriangle: lazy(() =>
    import("../games-folder/studying-triangle/studying-triangle")
  ),
  studyingRectangle: lazy(() =>
    import("../games-folder/studying-rectangle/studying-rectangle")
  ),
  studyingRhomb: lazy(() =>
    import("../games-folder/studying-rhomb/studying-rhomb")
  ),
  studyingOval: lazy(() =>
    import("../games-folder/studying-oval/studying-oval")
  ),
  ballDancing: lazy(() => import("../games-folder/ball-dancing/ball-dancing")),
  createHuman: lazy(() =>
    import("../games-folder/create-a-human/create-a-human")
  ),
  createFace: lazy(() => import("../games-folder/create-face/create-face")),
  watchCinema: lazy(() => import("../games-folder/watch-cinema/watch-cinema")),
  mostAttentive: lazy(() =>
    import("../games-folder/most-attentive/most-attentive")
  ),
  lookBinoculars: lazy(() =>
    import("../games-folder/look-binoculars/look-binoculars")
  ),
  talkingProjector: lazy(() =>
    import("../games-folder/talking-projector/talking-projector")
  ),
  lossingBoy: lazy(() => import("../games-folder/lossing-boy/lossing-boy")),
  grandmaHarvest: lazy(() =>
    import("../games-folder/grandma-harvest/grandma-harves-main")
  ),
  diamondsWithSurprise: lazy(() =>
    import("../games-folder/diamonds-with-surprise/diamonds-with-surprise")
  ),
  shoppingAhead: lazy(() =>
    import("../games-folder/shopping-ahead/shopping-ahead-main")
  ),
  lostThings: lazy(() => import("../games-folder/lost-things/lost-things")),
  lockers: lazy(() => import("../games-folder/lockers/lockers")),
  flyTraveller: lazy(() =>
    import("../games-folder/fly-traveller/fly-traveller-main")
  ),
  hungryFly: lazy(() => import("../games-folder/hungry-fly/hungry-fly")),
  findTurtles: lazy(() => import("../games-folder/find-turtles/find-turtles")),
  teaParty: lazy(() => import("../games-folder/tea-party/tea-party")),
  dobble: lazy(() => import("../games-folder/dobble/dobble")),
  rabbitInGuests: lazy(() =>
    import("../games-folder/rabbit-in-guests/rabbit-in-guests")
  ),
  christmasSocks: lazy(() =>
    import("../games-folder/christmas-socks/christmas-socks")
  ),
  lizardsAndTails: lazy(() =>
    import("../games-folder/lizards-and-tails/lizards-and-tails")
  ),
  fishMarket: lazy(() => import("../games-folder/fish-market/fish-market")),
  witchSpells: lazy(() => import("../games-folder/witch-spells/witch-spells")),
  playPiano: lazy(() => import("../games-folder/play-piano/play-piano")),
  heronInSwamp: lazy(() =>
    import("../games-folder/heron-in-swamp/heron-in-swamp")
  ),
  bigManufacture: lazy(() =>
    import("../games-folder/big-manufacture/big-manufacture")
  ),
  shoppingList: lazy(() =>
    import("../games-folder/shopping-list/shopping-list")
  ),
  acquaintanceMushrooms: lazy(() =>
    import("../games-folder/acquaintance-mushrooms/acquaintance-mushrooms-main")
  ),
  forestLife: lazy(() =>
    import("../games-folder/forest-life/forest-life-main")
  ),
  furnitureHouse: lazy(() =>
    import("../games-folder/furniture-house/furniture-house-main")
  ),
  lionHunt: lazy(() => import("../games-folder/lion-hunt/lion-hunt")),
  meltingIce: lazy(() => import("../games-folder/melting-ice/melting-ice")),
  sleplessNight: lazy(() =>
    import("../games-folder/slepless-night/slepless-night")
  ),
  letsPlayWithToys: lazy(() =>
    import("../games-folder/lets-play-with-toys/lets-play-with-toys")
  ),
  chickenKoKo: lazy(() =>
    import("../games-folder/chicken-ko-ko/chicken-ko-ko")
  ),
  trickyScales: lazy(() =>
    import("../games-folder/tricky-scales/tricky-scales")
  ),
  helpCooker: lazy(() => import("../games-folder/help-cooker/help-cooker")),
  sortPurchasesIntoBags: lazy(() =>
    import("../games-folder/sort-purchases-into-bags/sort-purchases-into-bags")
  ),
  dobbleL: lazy(() => import("../games-folder/dobble-l/dobble-l")),
  heavierLighter: lazy(() =>
    import("../games-folder/heavier-lighter/heavier-lighter")
  ),
  dobbleC: lazy(() => import("../games-folder/dobble-c/dobble-c")),
};

const GameRoutes = () => {
  const { gameId } = useParams();
  const LazyGameComponent = gameComponentsMap[gameId];

  if (!LazyGameComponent) {
    return <NotFound />;
  }
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ClipLoader size={50} color="#00BFFF" />
          <p
            style={{
              marginTop: "10px",
              fontSize: "18px",
              color: "#555",
              fontStyle: "Nunito Regular",
            }}
          >
            Загружаем материалы...
          </p>
        </div>
      }
    >
      <LazyGameComponent />
    </Suspense>
  );
};

export default GameRoutes;
